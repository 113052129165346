
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexaAs6F0uOO1PbNxqH8DD_45e04gSZQCarVD9TCXJYV94KEMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as termsxPmI_bz3lr0COX220qY_45hhLw9jpIPZB3X_C_45C0YXikUMeta } from "/opt/build/repo/pages/terms.vue?macro=true";
import { default as signup2kfXwozSqFCZlV75QBPeRhu0tktluQtErhlM5p3xE2IMeta } from "/opt/build/repo/pages/signup.vue?macro=true";
import { default as privacyJgcQvwZubR6yhc6N8xh1MhZ2ByebxVuUx1uXOag5a9UMeta } from "/opt/build/repo/pages/privacy.vue?macro=true";
import { default as societiesloxoibTH_08VNBB0t6_45WOWBHI4gB_45mQzA1_45rs9uFUfYMeta } from "/opt/build/repo/pages/societies.vue?macro=true";
import { default as indexHYeNxlBmuuNw6WlO4fVyq0aF1YM4RT9DrEpitwg78gMMeta } from "/opt/build/repo/pages/user/index.vue?macro=true";
import { default as index6KQYwgCt1bTcRoYAAw64KdwsuCkHaNYI9qRHwfpdOpQMeta } from "/opt/build/repo/pages/login/index.vue?macro=true";
import { default as productionsGH9IEC89qQDxoTHvjuhzIhy1mChnaJKA3IMQgm6wVKsMeta } from "/opt/build/repo/pages/productions.vue?macro=true";
import { default as indexIlhFKQlldK8GgSZqFp7w3IMsBNhA452OsMUa5ugRDgcMeta } from "/opt/build/repo/pages/administration/index.vue?macro=true";
import { default as finance_45reportsOESF01sbMvwOLdVcSxTDMiUWT7mniUpQvC8P5qwYUUkMeta } from "/opt/build/repo/pages/administration/finance-reports.vue?macro=true";
import { default as index1hnN5Adj_yyqf0bxlq73AhyNupdmsrZ6Bj4T3ZTE5LwMeta } from "/opt/build/repo/pages/administration/productions/index.vue?macro=true";
import { default as create1BdwFHHx8rMMD7Il2Go0L9mj6EULg5XyuDr93PkoM18Meta } from "/opt/build/repo/pages/administration/productions/create.vue?macro=true";
import { default as ticket_45lookupJqGTDiuVHRK4_BGGIDGPt2GfYLKnBigtURRzqXJdgnwMeta } from "/opt/build/repo/pages/administration/tools/ticket-lookup.vue?macro=true";
import { default as editKQecBUzYBf1d_45Kx_45rc86u4dD6cV8giTvSqjsYjQR2hYMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/edit.vue?macro=true";
import { default as index_usv2uM6JGk9Bo6pgPMdDWebWAV_f7sMFEO_454zX7iLsMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/index.vue?macro=true";
import { default as permissionscYw2Ce5G_XL9lg3h03vcJws__ZCvhzfH93tZim5d438Meta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/permissions.vue?macro=true";
import { default as indexoNr1RGEsuMQVLpT3afeHOBhKA3w6bGi_45OEns9aGYJ1UMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/index.vue?macro=true";
import { default as creategxfKvSJAQxQwnXqCvg8y8W4IytIg34tqJdab7GsBvH4Meta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/performances/create.vue?macro=true";
import { default as indexXqAvf2zHUk8r0ngdTO_458_45ySqntmkq_45FL6VfHnisEOTYMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/create/index.vue?macro=true";
import { default as indexQQA4fq7ZtuSWwaJ45fhISZuF5yEX_45ewJAD9ml_JtNjsMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/[bookingReference]/index.vue?macro=true";
import { default as editEjSC4BXNkrKhd9RDTgGcVv9QtDo8V7M9332CO_48yNMMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/performances/[performanceId]/edit.vue?macro=true";
import { default as indexNTE9DnpNKrysRqVC2Nchu6_45lFZcUjrUBf4OGN_45JZ0r0Meta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/performances/[performanceId]/index.vue?macro=true";
import { default as indexoKLoVGKsfkcJZjZG9MY1Wp_45l6kgvt3WMhYNl8sctEE0Meta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/create/[performanceId]/index.vue?macro=true";
import { default as administrationjK3GzBCdsYJWBEvEUiiPuXcE9Zfey_452A981x8MWtvuwMeta } from "/opt/build/repo/pages/administration.vue?macro=true";
import { default as indexRJMFuaHhuiouRZPVoLHIMbQXkVwdpdtJo3ksTBcpiVQMeta } from "/opt/build/repo/pages/box-office/index.vue?macro=true";
import { default as configtlGU7j3fQ0lIgoh8lP5cJpSRtn21LLHG5rteOk7EmhoMeta } from "/opt/build/repo/pages/box-office/config.vue?macro=true";
import { default as indexLqPDm3cTXL5YnTop3yq3dUQ_gvfORzQjlfxf5k9NUyIMeta } from "/opt/build/repo/pages/login/forgot/index.vue?macro=true";
import { default as index77HR7f_456IUJCEv893APxyMHdxdi3uOi_45GYct5av0v5UMeta } from "/opt/build/repo/pages/venue/[slug]/index.vue?macro=true";
import { default as indexys0BSJz_yzHddUOzLZGumlWj7_pn65HD5POQ1GS0J0UMeta } from "/opt/build/repo/pages/society/[slug]/index.vue?macro=true";
import { default as indexC99DUCM9FwEViByiI3Xw0WVFPkFVPngbZIs1Npdv1z8Meta } from "/opt/build/repo/pages/production/[slug]/book/index.vue?macro=true";
import { default as pay6h4fS6UNs8FlVqrV2QXZhxXZSc3HmgnampdcwNiWFh0Meta } from "/opt/build/repo/pages/production/[slug]/book/[performanceId]/pay.vue?macro=true";
import { default as indexuWpS8v6Z8cPWbiZeJtIuyM5bMkoX5KdaEmCDdtCTDhkMeta } from "/opt/build/repo/pages/production/[slug]/book/[performanceId]/index.vue?macro=true";
import { default as ticketsHGDHn4SLt_DXGTmyYujoQsqdkXE6IXznCWg1vcH_9pgMeta } from "/opt/build/repo/pages/production/[slug]/book/[performanceId]/tickets.vue?macro=true";
import { default as overviewYEfZaS8M9A0StNOYQIKAJsGQYLYhxl15_C8Rz0jAVQcMeta } from "/opt/build/repo/pages/production/[slug]/book/[performanceId]/overview.vue?macro=true";
import { default as warningskTp9hOZJbqAGk_4586FGE51xwEWNvaiZB42HAEoQb5WnUMeta } from "/opt/build/repo/pages/production/[slug]/book/[performanceId]/warnings.vue?macro=true";
import { default as bookyb1MVpDV_3BET_VG_tm_45P4kalx7pWF6obJ608ueAGocMeta } from "/opt/build/repo/pages/production/[slug]/book.vue?macro=true";
import { default as indexc28a81N2Zu_1YPz5XvQ5i4jjygCdQBnEs3cLLz1YcwgMeta } from "/opt/build/repo/pages/production/[slug]/index.vue?macro=true";
import { default as scanunT_45sOddlqGZ9ZR7azW6OxGb3hc_45zzEKdCbRM6l_45J1MMeta } from "/opt/build/repo/pages/box-office/[performanceId]/scan.vue?macro=true";
import { default as payiuXbQCwkVNYSY7tkLAgzZTa8pJsf_4557mNfxQx_45Rq_TMMeta } from "/opt/build/repo/pages/box-office/[performanceId]/sell/pay.vue?macro=true";
import { default as indexX2xz_78amk0q7VTPBTJneGaT16dL9Roq6_g9mVowzMAMeta } from "/opt/build/repo/pages/box-office/[performanceId]/sell/index.vue?macro=true";
import { default as sell3_hBl4N6FZMCsJcK6HBqyu3tGGw8RBhITr8duSpKR_450Meta } from "/opt/build/repo/pages/box-office/[performanceId]/sell.vue?macro=true";
import { default as indexkHUBOMglyYwGgSgomf95oqKPWap37zKlFeBO9_45j4wBcMeta } from "/opt/build/repo/pages/box-office/[performanceId]/index.vue?macro=true";
import { default as _91performanceId_93f8svqmpi5Thi2Ut2y_45cDTranlSSffmjJ0Nc3OAcV0SIMeta } from "/opt/build/repo/pages/box-office/[performanceId].vue?macro=true";
import { default as indexJBUAvvTpX1qJc_h5gty28kMCGVL7p2W5q11mGIW8U18Meta } from "/opt/build/repo/pages/login/forgot/[token]/index.vue?macro=true";
import { default as indexXvK3uAn0_KxkM9qkDFkpYkHSVp_d9uyJ8t_45PN6ry4L4Meta } from "/opt/build/repo/pages/login/activate/[token]/index.vue?macro=true";
import { default as indexzd1lMaS6S5l6XYn9kY92JNWDafTpT7_aUo_45MoTJ7V9YMeta } from "/opt/build/repo/pages/user/booking/[reference]/index.vue?macro=true";
import { default as indexm4btHBrgvx1k_45bZOypcTUbW2xx2rJEF1MEeXHAgSiuwMeta } from "/opt/build/repo/pages/user/email-verify/[token]/index.vue?macro=true";
import { default as ticketsqliAN6s_eoZ6adkCgM39sU1YhJF_Ag6qQ4WZ4t268UUMeta } from "/opt/build/repo/pages/user/booking/[reference]/tickets.vue?macro=true";
import { default as indexQqG_45ktTX7jcd_45MeEgeRxz74vgNhP2Vd3Pj8yqnR_45GPEMeta } from "/opt/build/repo/pages/publicity-screen/[venueSlugs]/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/opt/build/repo/pages/terms.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signup2kfXwozSqFCZlV75QBPeRhu0tktluQtErhlM5p3xE2IMeta || {},
    component: () => import("/opt/build/repo/pages/signup.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/build/repo/pages/privacy.vue")
  },
  {
    name: "societies",
    path: "/societies",
    component: () => import("/opt/build/repo/pages/societies.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: indexHYeNxlBmuuNw6WlO4fVyq0aF1YM4RT9DrEpitwg78gMMeta || {},
    component: () => import("/opt/build/repo/pages/user/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: index6KQYwgCt1bTcRoYAAw64KdwsuCkHaNYI9qRHwfpdOpQMeta || {},
    component: () => import("/opt/build/repo/pages/login/index.vue")
  },
  {
    name: "productions",
    path: "/productions",
    component: () => import("/opt/build/repo/pages/productions.vue")
  },
  {
    name: administrationjK3GzBCdsYJWBEvEUiiPuXcE9Zfey_452A981x8MWtvuwMeta?.name,
    path: "/administration",
    meta: administrationjK3GzBCdsYJWBEvEUiiPuXcE9Zfey_452A981x8MWtvuwMeta || {},
    component: () => import("/opt/build/repo/pages/administration.vue"),
    children: [
  {
    name: "administration",
    path: "",
    component: () => import("/opt/build/repo/pages/administration/index.vue")
  },
  {
    name: "administration-finance-reports",
    path: "finance-reports",
    meta: finance_45reportsOESF01sbMvwOLdVcSxTDMiUWT7mniUpQvC8P5qwYUUkMeta || {},
    component: () => import("/opt/build/repo/pages/administration/finance-reports.vue")
  },
  {
    name: "administration-productions",
    path: "productions",
    component: () => import("/opt/build/repo/pages/administration/productions/index.vue")
  },
  {
    name: "administration-productions-create",
    path: "productions/create",
    component: () => import("/opt/build/repo/pages/administration/productions/create.vue")
  },
  {
    name: "administration-tools-ticket-lookup",
    path: "tools/ticket-lookup",
    component: () => import("/opt/build/repo/pages/administration/tools/ticket-lookup.vue")
  },
  {
    name: "administration-productions-productionSlug-edit",
    path: "productions/:productionSlug()/edit",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/edit.vue")
  },
  {
    name: "administration-productions-productionSlug",
    path: "productions/:productionSlug()",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/index.vue")
  },
  {
    name: "administration-productions-productionSlug-permissions",
    path: "productions/:productionSlug()/permissions",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/permissions.vue")
  },
  {
    name: "administration-productions-productionSlug-bookings",
    path: "productions/:productionSlug()/bookings",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/index.vue")
  },
  {
    name: "administration-productions-productionSlug-performances-create",
    path: "productions/:productionSlug()/performances/create",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/performances/create.vue")
  },
  {
    name: "administration-productions-productionSlug-bookings-create",
    path: "productions/:productionSlug()/bookings/create",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/create/index.vue")
  },
  {
    name: "administration-productions-productionSlug-bookings-bookingReference",
    path: "productions/:productionSlug()/bookings/:bookingReference()",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/[bookingReference]/index.vue")
  },
  {
    name: "administration-productions-productionSlug-performances-performanceId-edit",
    path: "productions/:productionSlug()/performances/:performanceId()/edit",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/performances/[performanceId]/edit.vue")
  },
  {
    name: "administration-productions-productionSlug-performances-performanceId",
    path: "productions/:productionSlug()/performances/:performanceId()",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/performances/[performanceId]/index.vue")
  },
  {
    name: "administration-productions-productionSlug-bookings-create-performanceId",
    path: "productions/:productionSlug()/bookings/create/:performanceId()",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/create/[performanceId]/index.vue")
  }
]
  },
  {
    name: "box-office",
    path: "/box-office",
    meta: indexRJMFuaHhuiouRZPVoLHIMbQXkVwdpdtJo3ksTBcpiVQMeta || {},
    component: () => import("/opt/build/repo/pages/box-office/index.vue")
  },
  {
    name: "box-office-config",
    path: "/box-office/config",
    meta: configtlGU7j3fQ0lIgoh8lP5cJpSRtn21LLHG5rteOk7EmhoMeta || {},
    component: () => import("/opt/build/repo/pages/box-office/config.vue")
  },
  {
    name: "login-forgot",
    path: "/login/forgot",
    meta: indexLqPDm3cTXL5YnTop3yq3dUQ_gvfORzQjlfxf5k9NUyIMeta || {},
    component: () => import("/opt/build/repo/pages/login/forgot/index.vue")
  },
  {
    name: "venue-slug",
    path: "/venue/:slug()",
    component: () => import("/opt/build/repo/pages/venue/[slug]/index.vue")
  },
  {
    name: "society-slug",
    path: "/society/:slug()",
    component: () => import("/opt/build/repo/pages/society/[slug]/index.vue")
  },
  {
    name: bookyb1MVpDV_3BET_VG_tm_45P4kalx7pWF6obJ608ueAGocMeta?.name,
    path: "/production/:slug()/book",
    meta: bookyb1MVpDV_3BET_VG_tm_45P4kalx7pWF6obJ608ueAGocMeta || {},
    component: () => import("/opt/build/repo/pages/production/[slug]/book.vue"),
    children: [
  {
    name: "production-slug-book",
    path: "",
    component: () => import("/opt/build/repo/pages/production/[slug]/book/index.vue")
  },
  {
    name: "production-slug-book-performanceId-pay",
    path: ":performanceId()/pay",
    component: () => import("/opt/build/repo/pages/production/[slug]/book/[performanceId]/pay.vue")
  },
  {
    name: "production-slug-book-performanceId",
    path: ":performanceId()",
    component: () => import("/opt/build/repo/pages/production/[slug]/book/[performanceId]/index.vue")
  },
  {
    name: "production-slug-book-performanceId-tickets",
    path: ":performanceId()/tickets",
    component: () => import("/opt/build/repo/pages/production/[slug]/book/[performanceId]/tickets.vue")
  },
  {
    name: "production-slug-book-performanceId-overview",
    path: ":performanceId()/overview",
    component: () => import("/opt/build/repo/pages/production/[slug]/book/[performanceId]/overview.vue")
  },
  {
    name: "production-slug-book-performanceId-warnings",
    path: ":performanceId()/warnings",
    component: () => import("/opt/build/repo/pages/production/[slug]/book/[performanceId]/warnings.vue")
  }
]
  },
  {
    name: "production-slug",
    path: "/production/:slug()",
    component: () => import("/opt/build/repo/pages/production/[slug]/index.vue")
  },
  {
    name: _91performanceId_93f8svqmpi5Thi2Ut2y_45cDTranlSSffmjJ0Nc3OAcV0SIMeta?.name,
    path: "/box-office/:performanceId()",
    meta: _91performanceId_93f8svqmpi5Thi2Ut2y_45cDTranlSSffmjJ0Nc3OAcV0SIMeta || {},
    component: () => import("/opt/build/repo/pages/box-office/[performanceId].vue"),
    children: [
  {
    name: "box-office-performanceId-scan",
    path: "scan",
    component: () => import("/opt/build/repo/pages/box-office/[performanceId]/scan.vue")
  },
  {
    name: sell3_hBl4N6FZMCsJcK6HBqyu3tGGw8RBhITr8duSpKR_450Meta?.name,
    path: "sell",
    component: () => import("/opt/build/repo/pages/box-office/[performanceId]/sell.vue"),
    children: [
  {
    name: "box-office-performanceId-sell-pay",
    path: "pay",
    component: () => import("/opt/build/repo/pages/box-office/[performanceId]/sell/pay.vue")
  },
  {
    name: "box-office-performanceId-sell",
    path: "",
    component: () => import("/opt/build/repo/pages/box-office/[performanceId]/sell/index.vue")
  }
]
  },
  {
    name: "box-office-performanceId",
    path: "",
    component: () => import("/opt/build/repo/pages/box-office/[performanceId]/index.vue")
  }
]
  },
  {
    name: "login-forgot-token",
    path: "/login/forgot/:token()",
    meta: indexJBUAvvTpX1qJc_h5gty28kMCGVL7p2W5q11mGIW8U18Meta || {},
    component: () => import("/opt/build/repo/pages/login/forgot/[token]/index.vue")
  },
  {
    name: "login-activate-token",
    path: "/login/activate/:token()",
    meta: indexXvK3uAn0_KxkM9qkDFkpYkHSVp_d9uyJ8t_45PN6ry4L4Meta || {},
    component: () => import("/opt/build/repo/pages/login/activate/[token]/index.vue")
  },
  {
    name: "user-booking-reference",
    path: "/user/booking/:reference()",
    meta: indexzd1lMaS6S5l6XYn9kY92JNWDafTpT7_aUo_45MoTJ7V9YMeta || {},
    component: () => import("/opt/build/repo/pages/user/booking/[reference]/index.vue")
  },
  {
    name: "user-email-verify-token",
    path: "/user/email-verify/:token()",
    meta: indexm4btHBrgvx1k_45bZOypcTUbW2xx2rJEF1MEeXHAgSiuwMeta || {},
    component: () => import("/opt/build/repo/pages/user/email-verify/[token]/index.vue")
  },
  {
    name: "user-booking-reference-tickets",
    path: "/user/booking/:reference()/tickets",
    meta: ticketsqliAN6s_eoZ6adkCgM39sU1YhJF_Ag6qQ4WZ4t268UUMeta || {},
    component: () => import("/opt/build/repo/pages/user/booking/[reference]/tickets.vue")
  },
  {
    name: "publicity-screen-venueSlugs",
    path: "/publicity-screen/:venueSlugs()",
    meta: indexQqG_45ktTX7jcd_45MeEgeRxz74vgNhP2Vd3Pj8yqnR_45GPEMeta || {},
    component: () => import("/opt/build/repo/pages/publicity-screen/[venueSlugs]/index.vue")
  }
]