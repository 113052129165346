export default [
  "/opt/build/repo/components/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/components/global/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/components/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/layouts/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/plugins/**/*.{js,ts,mjs}",
  "/opt/build/repo/composables/**/*.{js,ts,mjs}",
  "/opt/build/repo/utils/**/*.{js,ts,mjs}",
  "/opt/build/repo/pages/index.vue",
  "/opt/build/repo/pages/terms.vue",
  "/opt/build/repo/pages/signup.vue",
  "/opt/build/repo/pages/privacy.vue",
  "/opt/build/repo/pages/societies.vue",
  "/opt/build/repo/pages/user/index.vue",
  "/opt/build/repo/pages/login/index.vue",
  "/opt/build/repo/pages/productions.vue",
  "/opt/build/repo/pages/administration.vue",
  "/opt/build/repo/pages/administration/index.vue",
  "/opt/build/repo/pages/administration/finance-reports.vue",
  "/opt/build/repo/pages/administration/productions/index.vue",
  "/opt/build/repo/pages/administration/productions/create.vue",
  "/opt/build/repo/pages/administration/tools/ticket-lookup.vue",
  "/opt/build/repo/pages/administration/productions/[productionSlug]/edit.vue",
  "/opt/build/repo/pages/administration/productions/[productionSlug]/index.vue",
  "/opt/build/repo/pages/administration/productions/[productionSlug]/permissions.vue",
  "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/index.vue",
  "/opt/build/repo/pages/administration/productions/[productionSlug]/performances/create.vue",
  "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/create/index.vue",
  "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/[bookingReference]/index.vue",
  "/opt/build/repo/pages/administration/productions/[productionSlug]/performances/[performanceId]/edit.vue",
  "/opt/build/repo/pages/administration/productions/[productionSlug]/performances/[performanceId]/index.vue",
  "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/create/[performanceId]/index.vue",
  "/opt/build/repo/pages/box-office/index.vue",
  "/opt/build/repo/pages/box-office/config.vue",
  "/opt/build/repo/pages/login/forgot/index.vue",
  "/opt/build/repo/pages/venue/[slug]/index.vue",
  "/opt/build/repo/pages/society/[slug]/index.vue",
  "/opt/build/repo/pages/production/[slug]/book.vue",
  "/opt/build/repo/pages/production/[slug]/book/index.vue",
  "/opt/build/repo/pages/production/[slug]/book/[performanceId]/pay.vue",
  "/opt/build/repo/pages/production/[slug]/book/[performanceId]/index.vue",
  "/opt/build/repo/pages/production/[slug]/book/[performanceId]/tickets.vue",
  "/opt/build/repo/pages/production/[slug]/book/[performanceId]/overview.vue",
  "/opt/build/repo/pages/production/[slug]/book/[performanceId]/warnings.vue",
  "/opt/build/repo/pages/production/[slug]/index.vue",
  "/opt/build/repo/pages/box-office/[performanceId].vue",
  "/opt/build/repo/pages/box-office/[performanceId]/scan.vue",
  "/opt/build/repo/pages/box-office/[performanceId]/sell.vue",
  "/opt/build/repo/pages/box-office/[performanceId]/sell/pay.vue",
  "/opt/build/repo/pages/box-office/[performanceId]/sell/index.vue",
  "/opt/build/repo/pages/box-office/[performanceId]/index.vue",
  "/opt/build/repo/pages/login/forgot/[token]/index.vue",
  "/opt/build/repo/pages/login/activate/[token]/index.vue",
  "/opt/build/repo/pages/user/booking/[reference]/index.vue",
  "/opt/build/repo/pages/user/email-verify/[token]/index.vue",
  "/opt/build/repo/pages/user/booking/[reference]/tickets.vue",
  "/opt/build/repo/pages/publicity-screen/[venueSlugs]/index.vue",
  "/opt/build/repo/{A,a}pp.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/{E,e}rror.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/app.config.{js,ts,mjs}",
  "/opt/build/repo/app/spa-loading-template.html",
  "/opt/build/repo/components/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/components/global/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/components/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/layouts/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/plugins/**/*.{js,ts,mjs}",
  "/opt/build/repo/composables/**/*.{js,ts,mjs}",
  "/opt/build/repo/utils/**/*.{js,ts,mjs}",
  "/opt/build/repo/pages/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/{A,a}pp.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/{E,e}rror.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/app.config.{js,ts,mjs}",
  "/opt/build/repo/app/spa-loading-template.html"
]